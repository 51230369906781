.formAdd {
    padding-left: 400px;
    padding-right: 400px;
}

body {
    margin: 0;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 20px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ui.borderless.top.fixed.menu {
    background: -webkit-gradient(linear,right top,left top,from(#FEBE39),to(#EA5826));
    background: linear-gradient(120deg,#FEBE39,#EA5826)
}

.search {
    width: 50%;
}

div.scroll {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto
}

.ribbonLeft {
    position: absolute;
    left: -5px;
    top: 27px;
    z-index: 0;
    overflow: hidden;
    width: 75px;
    height: 75px
}

    .ribbonLeft span {
        color: #fff;
        text-align: center;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 100px;
        display: block;
        background: #ff4136;
        background: linear-gradient(#F70505 0%, #ff4136 100%);
        box-shadow: 0 3px 10px -5px #000;
        position: absolute;
        top: 19px;
        left: -21px;
        font-weight: 700;
        line-height: 20px
    }

        .ribbonLeft span:before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            z-index: -1;
            border-left: 3px solid #ff4136;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #ff4136
        }

        .ribbonLeft span:after {
            content: '';
            position: absolute;
            right: 0%;
            top: 100%;
            z-index: -1;
            border-right: 3px solid #ff4136;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #ff4136
        }

    .ribbonLeft.r1 span {
        background: #f60
    }

        .ribbonLeft.r1 span:before {
            border-left-color: #f60;
            border-top-color: #f60
        }

        .ribbonLeft.r1 span:after {
            border-right-color: #f60;
            border-top-color: #f60
        }

    .ribbonLeft.r2 span {
        background: #093
    }

        .ribbonLeft.r2 span:before {
            border-left-color: #093;
            border-top-color: #093
        }

        .ribbonLeft.r2 span:after {
            border-right-color: #093;
            border-top-color: #093
        }

    .ribbonLeft.r3 span {
        background: #06c
    }

        .ribbonLeft.r3 span:before {
            border-left-color: #06c;
            border-top-color: #06c
        }

        .ribbonLeft.r3 span:after {
            border-right-color: #06c;
            border-top-color: #06c
        }

    .ribbonLeft.r4 span {
        background: #333
    }

        .ribbonLeft.r4 span:before {
            border-left-color: #333;
            border-top-color: #333
        }

        .ribbonLeft.r4 span:after {
            border-right-color: #333;
            border-top-color: #333
        }

.ribbonRight {
    position: absolute;
    right: -5px;
    top: 27px;
    z-index: 0;
    overflow: hidden;
    width: 75px;
    height: 75px
}

    .ribbonRight span {
        color: #fff;
        text-align: center;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        background: #ff4136;
        background: linear-gradient(#F70505 0%, #ff4136 100%);
        box-shadow: 0 3px 10px -5px #000;
        position: absolute;
        top: 19px;
        right: -21px;
        font-weight: 700;
        line-height: 20px
    }

        .ribbonRight span:before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            z-index: -1;
            border-left: 3px solid #ff4136;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #ff4136
        }

        .ribbonRight span:after {
            content: '';
            position: absolute;
            right: 0%;
            top: 100%;
            z-index: -1;
            border-right: 3px solid #ff4136;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #ff4136
        }

    .ribbonRight.r1 span {
        background: #f60
    }

        .ribbonRight.r1 span:before {
            border-left-color: #f60;
            border-top-color: #f60
        }

        .ribbonRight.r1 span:after {
            border-right-color: #f60;
            border-top-color: #f60
        }

    .ribbonRight.r2 span {
        background: #093
    }

        .ribbonRight.r2 span:before {
            border-left-color: #093;
            border-top-color: #093
        }

        .ribbonRight.r2 span:after {
            border-right-color: #093;
            border-top-color: #093
        }

    .ribbonRight.r3 span {
        background: #06c
    }

        .ribbonRight.r3 span:before {
            border-left-color: #06c;
            border-top-color: #06c
        }

        .ribbonRight.r3 span:after {
            border-right-color: #06c;
            border-top-color: #06c
        }

    .ribbonRight.r4 span {
        background: #333
    }

        .ribbonRight.r4 span:before {
            border-left-color: #333;
            border-top-color: #333
        }

        .ribbonRight.r4 span:after {
            border-right-color: #333;
            border-top-color: #333
        }

.css-1g86id8-MuiTreeItem-content.Mui-selected {
    background-color: inherit !important;
}

.tagfranchiseur {
    height: 32px;
    position: absolute !important;
    bottom: auto !important;
    right: 0 !important;
    top: 0 !important;
    left: 0 !important;
    display: flex !important;
    display: -webkit-flex !important;
    align-items: center !important;
    flex-flow: row wrap !important;
    padding: 6px 6px 6px 8px !important;
    background-color: rgba(47,45,43,0.46);
    color: #ffffff !important;
    border-top-left-radius: .28571429rem !important;
    border-top-right-radius: .28571429rem !important;
}

.personnalisable {
    position: absolute;
    left: -5px;
    top: 27px;
    z-index: 0;
    overflow: hidden;
    width: 115px;
    height: 115px
}

    .personnalisable span {
        color: #fff;
        text-align: center;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 150px;
        display: block;
        background: #ff4136;
        background: linear-gradient(#F70505 0%, #ff4136 100%);
        box-shadow: 0 3px 10px -5px #000;
        position: absolute;
        top: 36px;
        left: -29px;
        font-weight: 700;
        line-height: 20px
    }

        .personnalisable span:before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            z-index: -1;
            border-left: 3px solid #ff4136;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #ff4136
        }

        .personnalisable span:after {
            content: '';
            position: absolute;
            right: 0%;
            top: 100%;
            z-index: -1;
            border-right: 3px solid #ff4136;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #ff4136
        }

.openingPack {
    position: absolute;
    right: -5px;
    top: 27px;
    z-index: 0;
    overflow: hidden;
    width: 115px;
    height: 115px;
}

    .openingPack span {
        color: #fff;
        text-align: center;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 150px;
        display: block;
        background: #3a9f3a;
        background: linear-gradient(#8FBC8F 0%, #3a9f3a 100%);
        box-shadow: 0 3px 10px -5px #000;
        position: absolute;
        top: 36px;
        right: -29px;
        font-weight: 700;
        line-height: 20px
    }

        .openingPack span:before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            z-index: -1;
            border-left: 3px solid #3a9f3a;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #3a9f3a
        }

        .openingPack span:after {
            content: '';
            position: absolute;
            right: 0%;
            top: 100%;
            z-index: -1;
            border-right: 3px solid #3a9f3a;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #3a9f3a
        }

.productTable {
    border: none !important;
    box-shadow: rgba(34, 36, 38, 0.25) 1px 1px 5px 1px !important;
    border-radius: 0 !important;
}

    .productTable thead tr {
        text-align: center;
        font-family: Roboto,sans-serif;
        font-size: 13px;
        background-color: white
    }

        .productTable thead tr th {
            text-transform: uppercase !important;
            border-left: none !important;
            border-right: none !important;
            background-color: white !important
        }

    .productTable tbody tr td {
        padding: 6px !important
    }

    .productTable tbody tr th {
        padding: 0 !important
    }

    .productTable tbody tr td span {
        min-height: 50px;
        border-left: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        padding-left: 10px !important
    }

    .productTable tbody tr td img {
        width: 70px;
        height: 70px;
        padding: 5px
    }

.editBtn {
    border: none;
    background-color: inherit !important;
    padding: 2px !important;
    margin: 5px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
}

    .editBtn :hover {
        font-size: 22px !important;
        cursor: pointer;
        border-radius: 2px;
        background-color: #ccc !important
    }

    .editBtn path :hover {
        cursor: pointer;
        font-size: 22px !important;
    }


.WithoutSpace > .row {
    padding: 0 !important;
}

    .WithoutSpace > .row > .column {
        margin: 0 !important;
    }

.scrollTable {
    width: 100% !important;
    height: 350px;
    display: inline-block;
    overflow: scroll;
    overflow-x: hidden !important
}

.ImgZoomIn:hover {
    -ms-transform: scale(2.5); /* IE 9 */
    -webkit-transform: scale(2.5); /* Safari 3-8 */
    transform: scale(2.5);
}

.BackGroundWhite {
    background-color: white !important
}

@media only screen and (max-width: 750px) {
    .ResponsiveColumn {
        float: left;
        margin: 0 50px;
        width: 250px;
        height: 39.201px
    }
}

@media only screen and (min-width: 751px) {
    .ResponsiveColumn {
        float: left;
        margin: 0 10%;
        width: 300px;
        height: 39.201px
    }
}


.notification {
    color: white;
    float: right;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    position: absolute;
    right: 10px;
    padding-top: 2px;
    font-weight: 600;
}

.notification-red {
    background: rgb(255, 0, 0, 0.6);
}

.notification-grey {
    background-color: grey;
    opacity: 0.4
}


.img-preview-Content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
    border: 2px solid #ddd;
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    max-width: 180px;
    margin: 0 auto;
}

.img-block-preview {
    width: 25%;
    min-width: 180px;
}
.img-block-preview :hover {
    transform: scale(1.1);
}

.btn-delete-file {
    border: 3px solid #a9a9a9;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
    position: absolute;
    background: transparent none !important;
    z-index:1
}

.btn-delete-file b {
   color: #7b7b7b;
}
